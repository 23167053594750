



































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { LectorQuestion } from "@/graphql/queries/Events.graphql";
import { LectorQuestion as LectorQuestionType } from "@/generated/graphql";
import { DataTableHeader } from "vuetify";

@Component({
  name: "LectorQuestionTable",
  apollo: {
    lectorQuestions: {
      query: LectorQuestion,
      fetchPolicy: "no-cache",
      variables() {
        return { event_id: this.$route.params.id };
      },
    },
  },
})
export default class LectorQuestionTable extends Vue {
  public lectorQuestions: LectorQuestionType[] = [];

  protected readonly headers: DataTableHeader[] = [
    { text: "ID Сообщения", value: "id", width: 150 },
    { text: "ФИО", value: "fullName" },
    { text: "Сообщение", value: "question" },
  ];

  public async refetch(): Promise<void> {
    await this.$apollo.queries.lectorQuestions.refetch();
  }
}
